import {
  Page,
  Input,
  Button
} from 'framework7-react';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';
import getParameterByName from '../../utils/getParameterByName.js';
import {
  setCurrentUserId,
  setOrganizationId,
  setServiceInstanceId, setToken
} from '../../utils/localStorage.js';
import {
  wechaQRList
} from '../../modules/shufu.js';
import CONFIG from 'GlobalConfigFile';
import { pageOrgByEnv, uiConfigs } from '../loginWithIdentityId/pageConfig';
import './index.css';

// 主体流程：对接his后，使用身份证或其他有效证件查询wechatQr
// 调用startServiceBySceneId开启流程
// 之前山东的是因为路由名称，以及写死的id，徐牙防的对接路由名称中没有orgId，无法通用

// 此路由通用对接his后的流程，定制话单独拆分处理
const LoginWithPatientIdentifier = function (props) {
  const { t, i18n } = useTranslation();
  // 定制化界面logo，notes等，需要去pageConfig中查询
  const server = CONFIG.getRemoteConfig().server;
  const uiEnv = pageOrgByEnv[server] || 'default';
  // ui配置
  const uiConfig = uiConfigs[uiEnv];
  // 证件类型
  const [certificateType, setCertificateType] = useState('patientIdCard');
  // 证件id
  const [certificateVal, setCertificateVal] = useState('');
  const providerId = getParameterByName('providerId');
  const organizationId = getParameterByName('organizationId');
  const [customConfig, setCustomConfig] = useState({});

  const selectedTypeConfigRef = useRef();

  useEffect(() => {
    if (!organizationId || !providerId) {
      window.$$f7.dialog.alert('url地址异常，请检查', false);
    }
    let customConfig;
    try {
      customConfig = require(`./customConfig/${organizationId}_config.js`).default;
    } catch (error) {
      console.log(`指定文件 customConfig/${organizationId}_config.js 不存在，将加载默认文件。`);
      customConfig = require('./customConfig/default_config.js').default;
    } finally {
      setCustomConfig(customConfig)
      selectedTypeConfigRef.current = customConfig.loginType.options[0];
    }
  }, [organizationId, providerId]);

  // 获取患者信息（手动或者扫码）后，创建用户
  const submitHandler = useCallback(async () => {
    // 获取当前登录方式是否需要转换
    const { needConvert, convertCertificateVal } = selectedTypeConfigRef.current;
    window.$$f7.preloader.show();
    try {
      const params = {
        organizationId,
        // [certificateType]: convertCertificateVal(certificateVal),
        [certificateType]: certificateVal,
        tag: 'ziping',
      }
      if (needConvert) {
        // 如果需要转换，则使用配置中的转换方法处理
        params[certificateType] = convertCertificateVal(certificateVal)
      }
      const data = await wechaQRList(params)
      const length = data.length;
      if (length === 0) {
        window.$$f7.dialog.alert('未查询到您的量表信息', false);
      } else if (length === 1) {
        const wechatQrInfo = data[0];
        const { sceneId, patientProfile } = wechatQrInfo;
        props.f7router.navigate(`/chat-for-his/?providerId=${providerId}`, {
          reloadCurrent: true,
          props: {
            noRestart: false,
            sceneId: sceneId,
            userId: patientProfile.id,
          }
        });
      } else if (length > 1) {
        props.f7router.navigate('/wechatQrList', {
          reloadCurrent: true,
          props: {
            qrList: data
          }
        });
      }
      window.$$f7.preloader.hide();
    } catch (e) {
      window.$$f7.preloader.hide();
      console.log('error', e)
    }
  }, [certificateType, certificateVal, props.f7router, providerId, organizationId])

  const renderPatientIdentifierInput = () => {
    const { loginTypeTitle, options } = customConfig.loginType || {};
    return (
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          {loginTypeTitle}
        </div>
        {/* <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}> */}
        <div >
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px #2979ff`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
            }}>
            <Input
              type='select'
              value={certificateType}
              onChange={(e) => {
                setCertificateType(e.target.value);
                selectedTypeConfigRef.current = customConfig.loginType.options.filter(item => item.value === e.target.value)[0];
                setCertificateVal('');
              }}
            >
              {options && options.map((item, index) => {
                const { label, value } = item;
                return (
                  <option value={value}>{label}</option>
                )
              })}
            </Input>
            <Input
              type="text"
              placeholder='请输入你的证件号码'
              value={certificateVal}
              onChange={(e) => {
                setCertificateVal(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
    )

  }

  // 判断卡号长度是否正确
  const isInputsValid = () => {
    if (!certificateVal) return false;
    const { cardLength } = selectedTypeConfigRef.current;
    // cardLength不存在，默认为不限制
    return !cardLength || certificateVal.length === cardLength;
  }

  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        if (!organizationId || !providerId) {
          window.$$f7.dialog.alert('url地址异常，请检查', false);
          return;
        }
        if (!isInputsValid()) return;
        submitHandler()
      }}
    >确定
    </Button>
  }

  // his无法登录，使用shufu-his系统生成的心测号登录
  const renderPatientIdentifierNumLogin = () => {
    return <div className='login-with-patientIdentifier-entry'><a onClick={jumpPatientIdentifierLogin}>使用心测号登录</a></div>
  }

  const jumpPatientIdentifierLogin = () => {
    props.f7router.navigate(`/loginWithPatientIdentifier/?organizationId=${organizationId}&providerId=${providerId}`)
  }


  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      <img className='welcome-shufu-logo-ricn' src={uiConfig.logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>欢迎！</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>

      {renderPatientIdentifierInput()}
      {renderPatientIdentifierNumLogin()}
      {renderSubmitButton()}
      <div style={{ textAlign: 'center', fontSize: '14px', color: '#8c8c8c' }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};

LoginWithPatientIdentifier.defaultProps = {

};
export default LoginWithPatientIdentifier;
